import { useEffect, useRef } from 'react'
import { FiX } from 'react-icons/fi'
import * as Styled from './styles'

interface WantTradeProps {
  isOpen: boolean
  handleRequestClose: () => void
}

export const WantTrade = ({ isOpen, handleRequestClose }: WantTradeProps): JSX.Element => {
  const wantTradeRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const closeOpenMenus = (e: any) => {
      if (wantTradeRef.current && isOpen && !wantTradeRef.current.contains(e.target)) {
        handleRequestClose()
        document.body.classList.remove('no-scrollbar')
      }
    }

    document.addEventListener('mousedown', closeOpenMenus)
  }, [handleRequestClose])
  return (
    <Styled.WantTradeContainer ref={wantTradeRef} isOpen={isOpen}>
      <Styled.WantTradeCloseButton onClick={handleRequestClose}>
        <FiX />
      </Styled.WantTradeCloseButton>
      <ul>
        {LinkItems.map((link, index) => (
          <li key={String(index + 1)}>
            <a href={link.url}>
              <img src={link.icon} alt="Vetor Gráfico" />
              {link.name}
            </a>
          </li>
        ))}
      </ul>
    </Styled.WantTradeContainer>
  )
}

const LinkItems = [
  {
    name: 'Email',
    url: 'mailto:negociador@goesnicoladelli.com.br',
    icon: '/assets/icons/WantTrade/Mail.svg',
  },
  {
    name: 'SMS',
    url: '#',
    icon: '/assets/icons/WantTrade/SMS.svg',
  },
  {
    name: 'Ligamos para você',
    url: '#',
    icon: '/assets/icons/WantTrade/Phone.svg',
  },
  {
    name: 'Boleto Online',
    url: '#',
    icon: '/assets/icons/WantTrade/Boleto.svg',
  },
  {
    name: 'Skype',
    url: '#',
    icon: '/assets/icons/WantTrade/Skype.svg',
  },
  {
    name: 'LinkedIn',
    url: 'https://www.linkedin.com/company/10628690/',
    icon: '/assets/icons/WantTrade/Linkedin.svg',
  },
  {
    name: 'Facebook',
    url: 'https://www.facebook.com/goesnicoladelli/',
    icon: '/assets/icons/WantTrade/Facebook.svg',
  },
  {
    name: 'Whatsapp',
    url: 'https://api.whatsapp.com/send?phone=554834612300&text=Ol%C3%A1%20recebi%20um%20SMS%20da%20G%C3%B3es%20Nicoladelli%20referente%20a%20Proposta%20de%20Acordo,%20pode%20me%20auxiliar',
    icon: '/assets/icons/WantTrade/Whatsapp.svg',
  },
  {
    name: 'Vamos Quitar',
    url: 'https://vamosquitar.com.br/',
    icon: '/assets/icons/WantTrade/Vamosquitar.png',
  },
]
