import { TitleOutlined } from 'components/reusables/TitleOutlined'
import * as Styled from './styles'
import './style.css'
import { CheckPhoneForm } from 'components/layout/CheckPhoneForm'

export const CheckPhone = (): JSX.Element => {
  return (
    <>
      <Styled.ContainerBody>
        <div className="container">
          <TitleOutlined id="faq">
            Verificar se o telefone que me ligou é da Góes & Nicoladelli
          </TitleOutlined>
          <CheckPhoneForm />
        </div>
      </Styled.ContainerBody>
    </>
  )
}
