import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import * as Styled from './styles'
import './style.css'

export const CheckPhoneFloatActionButton = (): JSX.Element => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      hideTooltip()
    }, 10000)
  }, [])

  const showTooltip = () => setIsTooltipOpen(true)
  const hideTooltip = () => setIsTooltipOpen(false)

  return (
    <Styled.CheckPhoneFloatActionButton
      className="tooltip-container"
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {isTooltipOpen && (
        <div className="tooltip">
          <img src="/assets/icons/UserIconWhite.svg" alt="Vetor Gráfico" width={'60px'}/>
          <p>
            Verificar telefones da <br />
            empresa Góes & Nicoladelli
          </p>
        </div>
      )}
      <Link to="/verificar-telefone">
        <img src="/assets/icons/CheckPhone.png" alt="Vetor Gráfico" width={'60px'} />
      </Link>
    </Styled.CheckPhoneFloatActionButton>
  )
}
