import { GenericService } from './GenericService'

interface sendContactFormDTO {
  nome: string
  email: string
  telefone: string
  cidade: string
  mensagem: string
}

interface sendCheckPhoneFormFormDTO {
  phone: string
}

class FormsService extends GenericService {
  constructor() {
    super('/api/V2')
  }

  async sendPrivacyRequest(formData: FormData) {
    return await this.http.post('/privacy-request-send', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  async sendContactForm(data: sendContactFormDTO) {
    return await this.http.post('/contact-send', {
      ...data,
    })
  }

  async getPrivacyRequestTypes() {
    return await this.http.get<{
      data: {
        id: number
        descricao: string
      }[]
    }>('/get-types')
  }

  async getPrivacyLinkTypes() {
    return await this.http.get<{
      data: {
        id: number
        descricao: string
      }[]
    }>('/get-links-with-company')
  }

  async sendPhoneFind(data: sendCheckPhoneFormFormDTO) {
    return await this.http.post('/phones/find', {
      ...data,
    })
  }
}

export default new FormsService()
