import * as Styled from './styles'

export const FooterCopyright = (): JSX.Element => {
  const year = new Date().getFullYear()

  return (
    <Styled.FooterCopyright>
      <p>© 1998 - {year} Góes &amp; Nicoladelli Advogados associados</p>
    </Styled.FooterCopyright>
  )
}
