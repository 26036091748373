import styled from 'styled-components'

export const Form = styled.form`
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 3rem;
  input[type='radio'] {
    appearance: none;
    display: grid;
    place-content: center;
    &::before {
      content: '';
      background: transparent;
      border-radius: 100%;
      border: 1px solid ${({ theme }) => theme.colors.white};
      display: inline-block;
      width: 1rem;
      height: 1rem;
      position: relative;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease;
    }
    &:checked::before {
      background-color: ${({ theme }) => theme.colors.gray[300]};
      box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.gray[500]};
    }
  }
  .select-solicitation-label {
    margin-top: 3rem;
  }

  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
    max-width: 100%;
  }
`
export const FormGroup = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
    &:not(.accept-form) {
      max-width: 50%;
    }
  }
`

export const FormLabel = styled.label`
  display: flex;
  gap: 0.625rem;
`

export const FormInput = styled.input`
  border: none;
  border-radius: ${({ theme }) => theme.sizes.borderRadius.md};
  padding: 1rem;
`

export const FormSelect = styled.select`
  border: none;
  border-radius: ${({ theme }) => theme.sizes.borderRadius.md};
  padding: 1rem;
`
