import { Button } from 'components/reusables/Button'
import { useState } from 'react'
import { FiX } from 'react-icons/fi'
import Modal from 'react-styled-modal'
import * as Styled from './styles'
import { Link } from 'react-router-dom'

export const WarnModal = (): JSX.Element => {
  const [modalIsOpen, setModalIsOpen] = useState(true)
  return (
    <Modal open={modalIsOpen} onClose={() => setModalIsOpen(false)} centered size="large">
      <Modal.Header>
        <Modal.Title>
          <img
            src="/assets/images/goes-logo-white.svg"
            alt="Góes &amp; Nicoladelli"
            draggable={false}
          />
        </Modal.Title>
        <Button style={{ padding: 0 }} variant="link" onClick={() => setModalIsOpen(false)}>
          <FiX />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Styled.ModalBodyTitle>Aviso</Styled.ModalBodyTitle>
        <Styled.ModalBodyParagraph>
          Informamos que todas as informações oficiais de qualquer tipo, veiculadas via e-mail,
          possuem como remetente o domínio <strong>@goesnicoladelli.com.br</strong>.
          <br /> <br />
          <strong>Evite golpes!</strong> Sempre confira os dados (Nome/CNPJ) do beneficiário ao
          pagar qualquer boleto ou realizar PIX. Faça pagamentos apenas para o Credor/Banco titular
          do crédito. Em caso de dúvidas ligue para 0800-710-2300 ou WhatsApp (48) 3461-2300
        </Styled.ModalBodyParagraph>
        <Styled.ModalBodyIconItem>
          <img src="/assets/icons/Phone.svg" alt="Vetor Gráfico" />
          0800-710-2300
        </Styled.ModalBodyIconItem>
        <Styled.ModalBodyIconItem>
          <img src="/assets/icons/Mail.svg" alt="Vetor Gráfico" />
          goesnicoladelli@goesnicoladelli.com.br
        </Styled.ModalBodyIconItem>
        <Styled.ModalBodyParagraph style={{ marginBottom: '1rem' }}>
          Obrigado!
        </Styled.ModalBodyParagraph>
        <Styled.ModalBodyBox>
          Quer saber se o número que entrou em contato com você é da Góes Nicoladelli? Valide neste
          {'  '}
          <strong>
            <Link to="/verificar-telefone">link</Link>
          </strong>
          .
        </Styled.ModalBodyBox>
      </Modal.Body>
    </Modal>
  )
}
