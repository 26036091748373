import { ErrorMessage } from '@hookform/error-message'
import { ModalCookieConsent } from 'components/layout/ModalCookieConsent'
import { Button } from 'components/reusables/Button'
import { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import FormsService from 'services/FormsService'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { isValidCPF } from 'utils/CPFValidator'
import * as Styled from './styles'
import { Link } from 'react-router-dom'

interface FormInputs {
  type: string
  name: string
  contract: string
  email: string
  cpf: string
  link: string
  description: string
  toggle: string | boolean
}

export const Form = (): JSX.Element => {
  const [requestType, setRequestType] = useState<
    {
      id: number
      descricao: string
    }[]
  >([])
  const [isOpen, setIsOpen] = useState(false)
  const [linkType, setLinkType] = useState<
    {
      id: number
      descricao: string
    }[]
  >([])
  const [file, setFile] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      const {
        data: { data },
      } = await FormsService.getPrivacyRequestTypes()
      setRequestType(data)

      const {
        data: { data: dataLinkType },
      } = await FormsService.getPrivacyLinkTypes()
      setLinkType(dataLinkType)
    }
    fetchData()
  }, [])

  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors, isDirty, isValid },
  } = useForm<FormInputs>({
    mode: 'onChange',
  })

  const Alert = withReactContent(Swal)

  const readFile = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const input = event.target.files[0]
      const name = input.name
      const size = input.size / 1024
      const arrFileName = name.split('.')
      const extension = arrFileName.pop()

      if (size > 1024) {
        alert('O arquivo tem que ser menor do que 2 MB.')
        event.target.value = ''
        return false
      }

      if (
        extension != 'doc'.toLocaleLowerCase() &&
        extension != 'docx'.toLocaleLowerCase() &&
        extension != 'pdf'.toLocaleLowerCase() &&
        extension != 'jpg'.toLocaleLowerCase() &&
        extension != 'jpeg'.toLocaleLowerCase() &&
        extension != 'png'.toLocaleLowerCase()
      ) {
        alert(
          'O arquivo tem que ser em um desses formatos: \n- doc ou docx\n- pdf\n- jpg ou jpeg\n- png',
        )
        event.target.value = ''
        return false
      }

      setFile(event.target.files[0])
    }
  }

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    const formData = new FormData()
    if (!data.toggle) {
      console.log('Marque o último campo do formulário')
      return
    }
    data.toggle = data.toggle ? 'on' : ''

    formData.append('nome', data.name)
    formData.append('contrato', data.contract)
    formData.append('email', data.email)
    formData.append('cpf', data.cpf)
    formData.append('vinculo', data.link)
    formData.append('tipo', data.type)
    formData.append('descricao', data.description)
    formData.append('toggle', data.toggle)

    if (file !== null) {
      formData.append('arquivo', file)
    }

    try {
      const response = await FormsService.sendPrivacyRequest(formData)
      reset()
      Alert.fire({
        title: 'Sucesso!',
        text: response.data.message,
        icon: 'success',
        confirmButtonColor: '#CA1F34',
      })
    } catch (err) {
      console.log('Não foi possível enviar o formulário')
      Alert.fire({
        title: 'Erro!',
        text: 'Não foi possível enviar sua solicitação.',
        icon: 'error',
        confirmButtonColor: '#CA1F34',
      })
    }
  }
  return (
    <Styled.Form onSubmit={handleSubmit(onSubmit)}>
      <Styled.FormGroup style={{ marginTop: '50px' }}>
        <Styled.FormInput
          placeholder="Nome completo*"
          required
          {...register('name', {
            required: 'Preencha este campo',
          })}
        />
        <ErrorMessage
          errors={errors}
          name="name"
          render={(data) => <p style={{ color: '#ff3232' }}>{data.message}</p>}
        />
        <Styled.FormInput
          placeholder="Informe o número de contrato (se houver)"
          {...register('contract')}
        />
        <Styled.FormInput
          type="email"
          placeholder="Informe seu melhor email*"
          required
          {...register('email', {
            required: 'Preencha este campo',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Preencha com um e-mail válido',
            },
          })}
        />
        <ErrorMessage
          errors={errors}
          name="email"
          render={(data) => <p style={{ color: '#ff3232' }}>{data.message}</p>}
        />
        <Controller
          name="cpf"
          control={control}
          defaultValue=""
          rules={{
            pattern: {
              value: /(\d{3}.?\d{3}.?\d{3}-?\d{2})/g,
              message: 'Preencha com um CPF válido',
            },
            required: {
              value: true,
              message: 'Preencha este campo',
            },
            validate: isValidCPF,
          }}
          render={({ field: { onChange, value } }) => (
            <Styled.FormInput
              as={InputMask}
              mask="999.999.999-99"
              placeholder="Informe seu CPF*"
              value={value}
              onChange={onChange}
            />
          )}
        />
        {errors?.cpf && errors.cpf?.type !== 'validate' && (
          <ErrorMessage
            errors={errors}
            name="cpf"
            render={(data) => <p style={{ color: '#ff3232' }}>{data.message}</p>}
          />
        )}
        {errors?.cpf && errors.cpf?.type === 'validate' && (
          <p style={{ color: '#ff3232', marginTop: '0' }}>Preencha este campo com um CPF válido</p>
        )}

        <Styled.FormSelect
          placeholder="Vínculo com a empresa*"
          defaultValue=""
          {...register('link', {
            required: 'Preencha este campo',
          })}
        >
          <option value="" disabled>
            Vínculo com a empresa*
          </option>
          {linkType?.map((item) => (
            <option key={item.id} value={item.id}>
              {item.descricao}
            </option>
          ))}
        </Styled.FormSelect>

        <ErrorMessage
          errors={errors}
          name="link"
          render={(data) => <p style={{ color: '#ff3232' }}>{data.message}</p>}
        />

        <Styled.FormInput
          as="textarea"
          rows={12}
          required
          placeholder="Descrição da solicitação*"
          {...register('description', {
            required: 'Preencha este campo',
          })}
        />
        <ErrorMessage
          errors={errors}
          name="description"
          render={(data) => <p style={{ color: '#ff3232' }}>{data.message}</p>}
        />

        <input type="file" id="arquivo" onChange={(event) => readFile(event)} />
      </Styled.FormGroup>
      <p className="select-solicitation-label">
        Selecione o tipo de solicitação que está fazendo:*
      </p>
      <Styled.FormGroup>
        {requestType?.map((requestItem) => (
          <Styled.RadioLabel key={requestItem.id}>
            <input
              type="radio"
              value={requestItem.id}
              id={String(requestItem.id)}
              {...register('type', {
                required: 'Preencha este campo',
              })}
            />
            <label className="radio-label" htmlFor={String(requestItem.id)}>
              {requestItem.descricao}
            </label>
          </Styled.RadioLabel>
        ))}
        <ErrorMessage
          errors={errors}
          name="type"
          render={(data) => <p style={{ color: '#ff3232' }}>{data.message}</p>}
        />
      </Styled.FormGroup>
      <Styled.FormGroup className="accept-form" style={{ marginTop: '50px' }}>
        <Styled.CheckboxLabelAux>
          <input
            type="checkbox"
            {...register('toggle', {
              required: 'Preencha este campo',
            })}
          />
          Li e concordo que os dados inseridos serão utilizados para contato administrativos e
          processos operacionais, <br /> de acordo com o item 11.2 no{' '}
          <Link to="/aviso-privacidade" style={{ color: 'yellow' }}>
            Aviso de Privacidade
          </Link>{' '}
          e a Lei Geral de Proteção de Dados.
          <br />
          <br />
          Entendo que a empresa pode exigir que eu verifique/valide minha identidade antes de
          atender à solicitação.
        </Styled.CheckboxLabelAux>
        <ErrorMessage
          errors={errors}
          name="toggle"
          render={(data) => <p style={{ color: '#ff3232' }}>{data.message}</p>}
        />
      </Styled.FormGroup>
      <Button
        type="submit"
        variant="tertiary"
        width={'30%'}
        mt={20}
        disabled={!isDirty || !isValid}
      >
        ENVIAR SOLICITAÇÃO
      </Button>
      {!isDirty ||
        (!isValid && (
          <p style={{ color: '#ff3232', marginTop: '1rem', fontSize: '12  px' }}>
            Há campos a serem preenchidos no formulário
          </p>
        ))}
      <Styled.Divisor />
      <ModalCookieConsent modalIsOpen={isOpen} setModalIsOpen={setIsOpen} />
      <p style={{ textAlign: 'center' }}>
        Se você quiser saber como o Góes e Nicoladelli realiza o tratamento de dados pessoais, saiba
        mais em{' '}
        <Link to="/aviso-privacidade" style={{ color: 'yellow', fontWeight: 'bold' }}>
          Aviso de Privacidade
        </Link>
      </p>
      <br></br>
      <p style={{ textAlign: 'left' }}>Observações:</p>
      <p style={{ textAlign: 'left' }}>
        * Quando na condição de operador de dados, não responderemos aos titulares, indicando quando
        possível o seu controlador.
      </p>
      <p style={{ textAlign: 'left' }}>
        ** A requisição de direitos como a exclusão de dados pessoais somente será permitida quando
        a base legal utilizada possilitar o procedimento.
      </p>
    </Styled.Form>
  )
}
