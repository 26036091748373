import styled from 'styled-components'

export const CheckPhoneFloatActionButton = styled.button`
  background: linear-gradient(180deg, #66bb6a 0%, #2d642f 100%);
  border-radius: 9px;
  padding: 1rem;
  border: none;
  position: fixed;
  bottom: 8rem;
  right: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 8;
  justify-content: center;
  img {
    margin-bottom: -10px;
    margin-left: -5px;
  }
`
