import { ErrorMessage } from '@hookform/error-message'
import { Button } from 'components/reusables/Button'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import FormsService from 'services/FormsService'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as Styled from './styles'
import { useState } from 'react'

interface FormInputs {
  phone: string
}

export const CheckPhoneForm = (): JSX.Element => {
  const [mask, setMask] = useState('')
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormInputs>({
    mode: 'onChange',
  })

  const Alert = withReactContent(Swal)
  const customUnmask = (value: any) => {
    if (value === '(') {
      value = ''
      setMask('')
      return value
    }
    return value
  }

  const customOnBlur = (value: any) => {
    if (value == '') {
      setMask('')
      return value
    }

    if (value.includes('40045057')) {
      value = '40045057'
      setMask('9999-9999')
      return value
    }

    if (value.startsWith('0800')) {
      if (value.length === 11) {
        setMask('9999-999-9999')
      } else if (value.length === 12) {
        setMask('9999-9999-9999')
      } else {
        value = ''
        setMask('')
      }
    } else if (value.length === 11) {
      if (value.startsWith('0')) {
        value = value.substring(1, 11)
        setMask('(99) 99999-9999')
      } else {
        setMask('(99) 99999-9999')
      }
    } else if (value.length === 10) {
      setMask('(99) 9999-9999')
    } else {
      value = ''
      setMask('')
    }

    return value
  }

  const setMaskNumber = (value: string) => {
    let formattedNumber = ''

    if (value == '40045057') {
      formattedNumber += '' + value.substring(0, 4)
      formattedNumber += '-' + value.substring(4, 8)
      return formattedNumber
    }

    if (value.startsWith('0800')) {
      if (value.length === 11) {
        formattedNumber += '' + value.substring(0, 4)
        formattedNumber += '-' + value.substring(4, 7)
        formattedNumber += '-' + value.substring(7, 11)
      } else if (value.length === 12) {
        formattedNumber += '' + value.substring(0, 4)
        formattedNumber += '-' + value.substring(4, 8)
        formattedNumber += '-' + value.substring(8, 12)
      } else {
        formattedNumber = ''
      }
    } else if (value.length === 10) {
      formattedNumber += '(' + value.substring(0, 2)
      formattedNumber += ') ' + value.substring(2, 6)
      formattedNumber += '-' + value.substring(6, 10)
    } else if (value.length === 11) {
      formattedNumber += '(' + value.substring(0, 2)
      formattedNumber += ') ' + value.substring(2, 7)
      formattedNumber += '-' + value.substring(7, 11)
    } else {
      formattedNumber = ''
    }

    return formattedNumber
  }

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    data.phone = setMaskNumber(data.phone)
    Alert.fire({
      title: 'Verificando...',
      html: 'Por favor espere enquanto nós processamos sua requisição',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      },
    })

    try {
      const response = await FormsService.sendPhoneFind(data)
      Swal.close()

      if (response.data.data == 'found') {
        Alert.fire({
          title: 'Sucesso!',
          text: response.data.message,
          icon: 'success',
          confirmButtonColor: '#CA1F34',
        })
      } else {
        Alert.fire({
          title: 'Erro!',
          text: response.data.message,
          icon: 'error',
          confirmButtonColor: '#CA1F34',
        })
      }
    } catch (err) {
      Alert.fire({
        title: 'Erro!',
        text: 'Erro na busca',
        icon: 'error',
        confirmButtonColor: '#CA1F34',
      })
    }
  }
  return (
    <Styled.Form onSubmit={handleSubmit(onSubmit)}>
      <Styled.FormGroup style={{ marginTop: '50px' }}>
        <Controller
          name="phone"
          control={control}
          defaultValue=""
          rules={{
            required: {
              value: true,
              message: 'Preencha este campo',
            },
          }}
          render={({ field: { onChange, value } }) => (
            <Styled.FormInput
              as={InputMask}
              mask={mask}
              maskChar={null}
              placeholder="Informe o número*"
              value={value}
              onBlur={(event: any) => {
                onChange(customOnBlur(event.target.value))
              }}
              onKeyUp={(event: any) => {
                onChange(customUnmask(event.target.value))
              }}
              //   onChange={onChange}
              onChange={(event: any) => {
                onChange(event.target.value.replace(/[^0-9]/g, ''))
              }}
            />
          )}
        />
        {errors?.phone && (
          <ErrorMessage
            errors={errors}
            name="phone"
            render={(data) => <p style={{ color: '#ff3232' }}>{data.message}</p>}
          />
        )}
      </Styled.FormGroup>
      <Button type="submit" variant="tertiary" width={'30%'} mt={20}>
        VERIFICAR
      </Button>
    </Styled.Form>
  )
}
