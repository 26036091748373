import styled from 'styled-components'

export const ModalBodyTitle = styled.h1`
  font-size: 32px;
  font-weight: 500;
  line-height: 2.5;
  color: ${({ theme }) => theme.colors.secondary};
`
export const ModalBodyParagraph = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  line-height: 25px;
  strong {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600;
  }
`

export const ModalBodyIconItem = styled.p`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
  img {
    margin-right: 0.625rem;
  }
`
export const ModalBodyBox = styled.p`
  background-color: #d9d9d9;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 6px;
  font-weight: 600;
  line-height: 25px;
  color: ${({ theme }) => theme.colors.gray};
  strong {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600;
  }
  strong a {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;
  }
`
